import { GetApp } from '@material-ui/icons'
import { Box, Button, Grid, TextField, Tooltip } from '@mui/material'
import useMediaQuery from '@mui/material/useMediaQuery'
import { DesktopDatePicker, MobileDatePicker } from '@mui/x-date-pickers'
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns'
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider'
import Locale from 'date-fns/locale/sq'
import { useHistory, useLocation } from 'react-router-dom'
import { exportLeavesCSV } from './../utils/exportLeavesCSV'
import { useDebounce } from '../../../utils/searchDebouncer'
import moment from 'moment'
import { useEffect, useState } from 'react'
import { exportLeavesDocs } from '../utils/exportLeavesDocs'

const TableHeader = ({ thisObject }) => {
  const isMobile = useMediaQuery('(max-width:767px)')
  const DatePickerComponent = isMobile ? MobileDatePicker : DesktopDatePicker

  const location = useLocation()
  const history = useHistory()

  const searchParams = new URLSearchParams(location.search)
  const initialSearchQuery = searchParams.get('search') || ''

  const [localSearchQuery, setLocalSearchQuery] = useState(initialSearchQuery)

  const debouncedSearchQuery = useDebounce(localSearchQuery, 500)

  useEffect(() => {
    if (debouncedSearchQuery !== initialSearchQuery) {
      updateSearchParams('search', debouncedSearchQuery)
    }
  }, [debouncedSearchQuery])

  const updateSearchParams = (key, value) => {
    if (value) {
      searchParams.set(key, value)
    } else {
      searchParams.delete(key)
    }
    history.push({ search: searchParams.toString() })
    thisObject?.loadDataTableData()
  }

  const handleInputChange = (event) => {
    const newQuery = event.target.value
    setLocalSearchQuery(newQuery)
    thisObject?.setState({ searchQuery: newQuery })
  }

  const isApprovedChecked = thisObject?.state?.filterList?.status?.length === 1 && thisObject?.state?.filterList?.status[0] === 'Approved'

  return (
    <LocalizationProvider dateAdapter={AdapterDateFns} adapterLocale={Locale}>
      <Grid container gap={3} sx={{ fieldset: { border: '1px solid' } }}>
        <Grid item>
          {!thisObject?.state?.selectedUserID && thisObject?.state?.activeTab === '1' && (
            <Box sx={{ display: 'flex', justifyContent: 'start' }}>
              <TextField placeholder='Kërko përdoruesin' value={localSearchQuery} size='small' onChange={handleInputChange} />
            </Box>
          )}
        </Grid>
        <Grid container item width='fit-content' gap={1}>
          <Grid item>
            <DatePickerComponent
              label='Prej:'
              openTo='year'
              inputFormat='dd/MM/yyyy'
              views={['year', 'month', 'day']}
              value={thisObject?.state?.fromDateValue}
              onChange={(e) => {
                const formattedDate = moment(e).format('YYYY-MM-DD')
                thisObject?.setState({ fromDateValue: formattedDate }, () => {
                  updateSearchParams('fromDateValue', formattedDate)
                })
              }}
              renderInput={(params) => <TextField size='small' {...params} sx={{ width: '150px' }} />}
            />
          </Grid>
          <Grid item>
            <DatePickerComponent
              label='Deri:'
              openTo='year'
              inputFormat='dd/MM/yyyy'
              views={['year', 'month', 'day']}
              value={thisObject?.state?.toDateValue}
              onChange={(e) => {
                const formattedDate = moment(e).format('YYYY-MM-DD')
                thisObject?.setState({ toDateValue: formattedDate }, () => {
                  updateSearchParams('toDateValue', formattedDate)
                })
              }}
              renderInput={(params) => <TextField size='small' {...params} sx={{ width: '150px' }} />}
            />
          </Grid>
        </Grid>
        <Grid item>
          <Button onClick={() => exportLeavesCSV(thisObject)} size='small' variant='contained' startIcon={<GetApp />}>
            Shkarko
          </Button>
        </Grid>

        {/* <Grid item>
          <Tooltip title={isApprovedChecked ? 'Shkarko te gjitha dokumentet e gjetura' : 'Filtro permes statusit Approved'}>

            <div>
              <Button
                onClick={() => exportLeavesDocs(thisObject)}
                disabled={!isApprovedChecked}
                size='small'
                variant='outlined'
                startIcon={<GetApp />}
              >
                Shkarko Aprovimet
              </Button>
            </div>
          </Tooltip>
        </Grid> */}
      </Grid>
    </LocalizationProvider>
  )
}

export default TableHeader
