import { Box, Grid, Card, Avatar, CardHeader, Typography, CardContent } from '@mui/material'
import BeachAccessIcon from '@material-ui/icons/BeachAccess'
import HourglassEmptyIcon from '@material-ui/icons/HourglassEmpty'

const SalaryCard = ({ leavesHours, worktimeHours }) => {
  const salesData = [
    {
      stats: `${parseFloat(worktimeHours).toFixed(1) || 0} Orë `,
      title: `Orë pune (${parseFloat(worktimeHours).toFixed(2) || 0})`,
      color: '#686D7690',
      icon: <HourglassEmptyIcon sx={{ fontSize: '1.75rem' }} />
    },
    {
      stats: `${parseFloat(leavesHours).toFixed(1) || 0} Orë`,
      color: '#00BEA5',
      title: `Pushime (${parseFloat(leavesHours).toFixed(2) || 0})`,
      icon: <BeachAccessIcon sx={{ fontSize: '1.75rem' }} />
    }
  ]
  const salesInfo = salesData.map((item, index) => (
    <Grid item xs={12} sm={12} md={6} lg={6} key={index}>
      <Box key={index} sx={{ display: 'flex', alignItems: 'center' }}>
        <Avatar
          variant='rounded'
          sx={{
            mr: 2,
            width: 44,
            height: 44,
            boxShadow: 3,
            color: 'common.white',
            backgroundColor: `${item.color}`
          }}
        >
          {item.icon}
        </Avatar>
        <Box sx={{ display: 'flex', flexDirection: 'column' }}>
          <Typography variant='caption'>{item.title}</Typography>
          <Typography variant='h6'>{item.stats}</Typography>
        </Box>
      </Box>
    </Grid>
  ))

  return (
    <Card sx={{ position: 'relative', zIndex: '99' }}>
      <CardHeader
        sx={{ padding: '24px 10px !important' }}
        title='Orët e punës'
        subheader={
          <Typography variant='body2'>
            <Box component='span' sx={{ fontWeight: 600, color: 'text.primary' }}>
              Orët e punës:
            </Box>{' '}
          </Typography>
        }
        titleTypographyProps={{
          sx: {
            marginBottom: '20px !important',
            lineHeight: '2rem !important',
            letterSpacing: '0.15px !important'
          }
        }}
      />
      <CardContent sx={{ pt: (theme) => `${theme.spacing(3)} !important` }}>
        <Grid container spacing={[5, 0]}>
          {salesInfo}
        </Grid>
      </CardContent>
    </Card>
  )
}

export default SalaryCard
