import { Grid } from '@mui/material'
import TotalHoursCard from './Cards/TotalHoursCard'
import SalaryCard from './Cards/SalaryCard'
import axios from 'axios'
import { useState } from 'react'
import { toast } from 'react-toastify'
import moment from 'moment'
import LeavesCalendar from './My_Leaves_Calendar/LeavesCalendar'
import { useQuery } from '@tanstack/react-query'

export default function MyWorkingHourse({ userId }) {
  const [valueDate, setValueDate] = useState(new Date())

  const fetchMyMonthlyHours = async (urlWithParams) => {
    try {
      const res = await axios.get(urlWithParams)
      console.log('res', res)
      if (res.data.success) {
        return res
      }
    } catch (error) {
      toast.error(error.res?.data?.message)
    }
  }
  let query = `target_year=${moment(valueDate).format('YYYY')}&target_month=${moment(valueDate).format('MM')}&userId=${userId}`

  let endpoint = `monthlyWorkingHours/myHours?${query}`

  const myMonthlyHours = useQuery({
    queryKey: ['myMonthlyHours', valueDate],
    queryFn: () => fetchMyMonthlyHours(endpoint),
    staleTime: 50000
  })
  const totalHours = myMonthlyHours?.data?.data?.totalLeaveHours + myMonthlyHours?.data?.data?.totalWorkHours

  return (
    <Grid container spacing={2} mt={3} pb={5} pl={2} pr={2}>
      <Grid item xs={12} md={12} lg={12} xl={4}>
        <TotalHoursCard totalHours={totalHours} userId={userId} />
      </Grid>
      <Grid item xs={12} md={12} lg={12} xl={8}>
        <SalaryCard leavesHours={myMonthlyHours?.data?.data?.totalLeaveHours} worktimeHours={myMonthlyHours?.data?.data?.totalWorkHours} />
      </Grid>
      <Grid item xs={12}>
        <LeavesCalendar valueDate={valueDate} setValueDate={setValueDate} myHours={myMonthlyHours?.data?.data?.data || []} />
      </Grid>
      {/* )} */}
    </Grid>
  )
}
