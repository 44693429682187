import { calculateContractEnd } from './calculateContractEnd'

export default function showDownloadNewContractButton(userFirstCall) {
  if (userFirstCall) {
    const currentDate = new Date()
    const endOfContract = calculateContractEnd(new Date(userFirstCall))

    const endOfContractMonthBefore = new Date(endOfContract)
    endOfContractMonthBefore.setMonth(endOfContract.getMonth() - 1)

    if (currentDate > endOfContractMonthBefore && currentDate < endOfContract) {
      return true
    }
    return false
  }
}
