import axios from 'axios'
import { DOCUMENTS_API } from './consts'

export const getAwsImage = (key) => {
  return `${DOCUMENTS_API}/images/?key=${key}`
}

export const sendAwsImage = () => {
  return `${DOCUMENTS_API}/images`
}

export const getAwsDocuments = (id, type) => {
  if (type === 'default') {
    return `${DOCUMENTS_API}/documents/default/${id}`
  } else if (type === 'new_contract') {
    return `${DOCUMENTS_API}/documents/new_contract/${id}`
  } else {
    return `${DOCUMENTS_API}/documents/${id}`
  }
}

export const setAwsDocuments = () => {
  return `${DOCUMENTS_API}/documents`
}

export const getDocTemplate = async (id) => {
  try {
    const { data } = await axios.get(`${DOCUMENTS_API}/documentTemplate/${id}`)
    return data
  } catch (error) {
    console.error(error.message)
    return null
  }
}
