import React, { Fragment } from 'react'
import { saveAs } from 'file-saver'
import { Card, CardHeader, CardBody, Col, Row, Button, Collapse } from 'reactstrap'
import pubsub from 'pubsub-js'
import DocumentInputModal from './DocumentInputModal'
import axios from 'axios'
import 'moment/locale/sq'
import { inject } from 'mobx-react'
import { getAwsDocuments, getAwsImage } from '../../utils/awsPaths'
import Swal from 'sweetalert2'
import moment from 'moment'

@inject('userStore')
class UserForms extends React.Component {
  constructor(props) {
    super()
    this.state = {
      isModalOpen: false,
      modalData: {},
      modalLoading: false,
      working_status: [],
      workingStatusSelected: '',
      showTrainingContract: false,
      resingInterview: []
    }
  }

  _toggleModal = () => {
    this.setState((prevState) => ({
      isModalOpen: !prevState.isModalOpen
    }))
  }

  async componentDidMount() {
    let userID = this.props.userId

    if (userID) {
      await axios
        .get(`resignInterview/user/${userID}?limit=1&sort=-createdAt`)
        .then(async (response) => {
          if (response.data.success === true) {
            this.setState({
              resingInterview: response.data.data
            })
          }
        })
        .catch((error) => {
          console.log('error', error)
        })
    }
  }

  _downloadTrainingContract = () => {
    this.setState({
      isModalOpen: true,
      modalData: {
        modalType: 'training_contract',
        title: 'Te dhënat mbi kontraten e trajnimeve',
        description: 'Ploteso te dhënat per te gjeneruar kontraten e trajnimeve.',
        fields: [
          {
            type: 'text',
            name: 'training_location',
            label: 'Lokacioni i trajnimeve:'
          },
          {
            type: 'datepicker',
            name: 'training_start',
            label: 'Data e fillimit te trajnimit:'
          },
          {
            type: 'datepicker',
            name: 'training_end',
            label: 'Data e mbarimit te trajnimit:'
          },
          {
            type: 'datepicker',
            name: 'work_start',
            label: 'Data e fillimit te kontrates:'
          },
          {
            type: 'datepicker',
            name: 'work_end',
            label: 'Data e mbarimit te kontrates:'
          },
          {
            type: 'timepicker',
            name: 'start_hour',
            label: 'Fillimi i trajnimit:'
          },
          {
            type: 'timepicker',
            name: 'end_hour',
            label: 'Mbarimi i trajnimit:'
          }
        ]
      }
    })
  }

  _downloadCollateral = () => {
    this.setState({
      isModalOpen: true,
      modalData: {
        modalType: 'collateral',
        title: 'Te dhënat mbi kolateralin',
        description: 'Ploteso te dhënat per te gjeneruar kolateralin.',
        fields: [
          {
            type: 'datepicker',
            name: 'leave_start',
            label: 'Data e fillimit:'
          },
          {
            type: 'datepicker',
            name: 'leave_end',
            label: 'Data e mbarimit:'
          },
          {
            type: 'number',
            name: 'amount',
            label: 'Shuma:'
          }
        ]
      }
    })
  }

  _downloadResignInterview = async (interviewId) => {
    Swal.fire({
      title: 'Ju lutem prisni...',
      text: 'Duke gjeneruar dokumentin',
      didOpen: () => {
        Swal.showLoading()
      }
    })
    const filename = `IntervistaDalese${this.props.userForm.first_name}_${this.props.userForm.last_name}.docx`

    const response = await axios.get(`resignInterview/${interviewId}/wordDoc`, { responseType: 'blob' })
    if (response.headers['content-type'] == 'application/vnd.openxmlformats-officedocument.wordprocessingml.document') {
      saveAs(response.data, filename)
      Swal.close()
      Swal.fire({
        title: 'Te dhenat u shkarkuan me sukses.',
        text: 'Dokumenti eshte gjeneruar me sukses!',
        type: 'success'
      })
    } else {
      if (!response.success) {
        console.log('Response failed with message:', response.message)
        Swal.fire({
          title: 'Gabim gjatë shkarkimit të dokumentit',
          text: `Gabim gjatë shkarkimit të dokumentit ${response.message}`,
          type: 'warning'
        })
      } else {
        console.log('Response failed:')
        Swal.fire({
          title: 'Gabim gjatë shkarkimit të dokumentit',
          text: 'Gabim gjatë shkarkimit të dokumentit',
          type: 'warning'
        })
      }
    }
  }
  _download = async (contractType, postData = {}) => {
    this.setState({ modalLoading: true })
    var filename = 'none.docx'
    switch (contractType) {
      case 'confidentiality_agreement': {
        postData = {
          document: 'confidentiality_agreement'
        }
        filename = 'ANEKS_Marreveshje_konfidencialiteti.docx'
        break
      }
      case 'job_contract': {
        postData = { ...postData, document: contractType }
        filename = 'Kontrate_pune.docx'
        break
      }
      case 'job_contract_part_time': {
        postData = { ...postData, document: contractType }
        filename = 'Kontrate_pune_part_time.docx'
        break
      }
      case 'warning': {
        postData = { ...postData, document: contractType }
        filename = 'Verejtje.docx'
        break
      }
      case 'work_end': {
        postData = { ...postData, document: contractType }
        filename = 'Shkeputje_Kontrate.docx'
        break
      }
      case 'training_contract': {
        postData = { ...postData, document: contractType }
        filename = 'Kontrata_trajnime.docx'
        break
      }
      case 'internship_contract': {
        postData = {
          document: 'internship_contract'
        }
        filename = 'Kontrata_e_praktikes.docx'
        break
      }
      case 'collateral': {
        postData = { ...postData, document: contractType }
        filename = 'Kolaterali.docx'
        break
      }
      case 'change_position': {
        postData = { ...postData, document: contractType }
        filename = 'Ndryshim_pozite_page.docx'
        break
      }
      case 'minimum_standards': {
        postData = { document: 'minimum_standards' }
        filename = 'Standardet_Minimale.docx'
        break
      }
      case 'job_duties_and_responsibilities': {
        postData = { document: 'job_duties_and_responsibilities' }
        filename = 'Detyrat_Dhe_Përgjegjësitë_e_punës.docx'
        break
      }
      case 'work_confirmation': {
        postData = { document: 'work_confirmation' }
        filename = 'Vertetimi_i_Punes.docx'
        break
      }
      case 'suspension': {
        postData = { document: 'suspension' }
        filename = 'Suspendim.docx'
        break
      }
      case 'demotedInPosition': {
        postData = { document: 'demotedInPosition' }
        filename = 'ulje_ne_pozite.docx'
        break
      }
      default:
        break
    }
    try {
      Swal.fire({
        title: 'Ju lutem prisni...',
        text: 'Duke gjeneruar dokumentin',
        didOpen: () => {
          Swal.showLoading()
        }
      })
      var response = await axios.post(getAwsDocuments(this.props.userId, 'default'), postData)
      saveAs(getAwsImage(response.data.file), filename)
      this.setState({ modalLoading: false, isModalOpen: false })
      Swal.close()
      Swal.fire({
        title: 'Te dhenat u shkarkuan me sukses.',
        text: 'Dokumenti eshte gjeneruar me sukses!',
        type: 'success'
      })
    } catch (e) {
      this.setState({
        modalLoading: false
      })
      pubsub.publish('showNotification', {
        message: 'Gabim gjatë shkarkimit të file',
        type: 'error'
      })
      console.log('Response failed:')
    }
  }

  _downloadMultipleDocuments = async () => {
    this.setState({ modalLoading: true })
    try {
      Swal.fire({
        title: 'Ju lutem prisni...',
        text: 'Duke gjeneruar dokumentet',
        didOpen: () => {
          Swal.showLoading()
        }
      })

      const response = await axios.post(getAwsDocuments(this.props.userId, 'new_contract'))

      const fileKey = response.data.file
      const zipFileUrl = getAwsImage(fileKey)
      const filename = `${this.props.userForm.first_name}_${this.props.userForm.last_name}_documents.zip`

      saveAs(zipFileUrl, filename)

      this.setState({ modalLoading: false, isModalOpen: false })
      Swal.close()
      Swal.fire({
        title: 'Të dhënat u shkarkuan me sukses.',
        text: 'Dokumentet janë gjeneruar me sukses!',
        icon: 'success'
      })
    } catch (e) {
      this.setState({ modalLoading: false })
      pubsub.publish('showNotification', {
        message: 'Gabim gjatë shkarkimit të file',
        type: 'error'
      })
      console.error('Response failed:', e)
    }
  }

  _sendEmail = () => {
    const emailReceiver = {
      email: this.props.userForm.email,
      sender: null
    }

    axios
      .post('/users/newContract', emailReceiver)
      .then((response) => {
        if (response.data.success) {
          pubsub.publish('showNotification', {
            message: 'Email u dërgua me sukses',
            type: 'success'
          })
        } else {
          pubsub.publish('showNotification', {
            message: 'Gabim gjatë dërgimit të email',
            type: 'error'
          })
        }
      })
      .catch((e) => {
        this.setState({ errorMessage: 'Server error' })
      })
  }
  render() {
    return (
      <Fragment>
        <DocumentInputModal
          className={this.state.modalLoading ? 'whirl standard' : ''}
          onComplete={this._onModalComplete}
          isOpen={this.state.isModalOpen}
          modalData={this.state.modalData}
          toggleModal={() => this._toggleModal()}
        />
        {!!this.props.shouldShowNewContractBtn ? (
          <Col md={6} style={{ marginBottom: 10, marginTop: 20, marginLeft: 'auto' }}>
            <div className='list-group mb0'>
              <div className='list-group-item'>
                <Button onClick={this._downloadMultipleDocuments} size='xs' color='info' className='btn-labeled float-right'>
                  <span className='btn-label'>
                    <i className='fa fa-download' />
                  </span>{' '}
                  Shkarko
                </Button>
                <em className='fa-fw fas fa-user-lock mr-2' />
                Kontrata e re
              </div>
            </div>
          </Col>
        ) : null}
        <Card outline color='secondary' className='mb-3'>
          <CardHeader color='secondary'>
            <strong>Format e gatshme të përdoruesit</strong>
          </CardHeader>
          <CardBody>
            <Row>
              <Col md={6} style={{ marginBottom: 10 }}>
                <div className='list-group mb0'>
                  <div className='list-group-item bt0'>
                    <Button
                      onClick={() => this._download('confidentiality_agreement')}
                      size='xs'
                      color='info'
                      className='btn-labeled float-right'
                    >
                      <span className='btn-label'>
                        <i className='fa fa-download' />
                      </span>{' '}
                      Shkarko
                    </Button>
                    <em className='fa-fw fas fa-user-lock mr-2' />
                    Kontrata e konfidencialitetit
                  </div>
                </div>
              </Col>
              <Col md={6} style={{ marginBottom: 10 }}>
                <div className='list-group mb0'>
                  <div className='list-group-item bt0'>
                    <Button onClick={() => this._download('job_contract')} size='xs' color='info' className='btn-labeled float-right'>
                      <span className='btn-label'>
                        <i className='fa fa-download' />
                      </span>{' '}
                      Shkarko
                    </Button>
                    <em className='fa-fw fas fa-briefcase mr-2' />
                    Kontrata e punës
                  </div>
                </div>
              </Col>
              <Col md={6} style={{ marginBottom: 10 }}>
                <div className='list-group mb0'>
                  <div className='list-group-item bt0'>
                    <Button onClick={() => this._download('work_confirmation')} size='xs' color='info' className='btn-labeled float-right'>
                      <span className='btn-label'>
                        <i className='fa fa-download' />
                      </span>{' '}
                      Shkarko
                    </Button>
                    <em className='fa-fw fas fa-user-check mr-2' />
                    Vërtetim pune
                  </div>
                </div>
              </Col>

              {this.state.showTrainingContract && (
                <>
                  <Col md={6} style={{ marginBottom: 10 }}>
                    <div className='list-group mb0'>
                      <div className='list-group-item bt0'>
                        <Button onClick={() => this._downloadTrainingContract()} size='xs' color='info' className='btn-labeled float-right'>
                          <span className='btn-label'>
                            <i className='fa fa-download' />
                          </span>{' '}
                          Shkarko
                        </Button>
                        <em className='fa-fw fas fa-chalkboard-teacher mr-2' />
                        Kontrata e trajnimeve
                      </div>
                    </div>
                  </Col>
                  <Col md={6} style={{ marginBottom: 10 }}>
                    <div className='list-group mb0'>
                      <div className='list-group-item bt0'>
                        <Button onClick={() => this._downloadCollateral()} size='xs' color='info' className='btn-labeled float-right'>
                          <span className='btn-label'>
                            <i className='fa fa-download' />
                          </span>{' '}
                          Shkarko
                        </Button>
                        <em className='fa-fw fas fa-money-check-alt mr-2' />
                        Kolaterali
                      </div>
                    </div>
                  </Col>
                </>
              )}
              <Col md={6} style={{ marginBottom: 10 }}>
                <div className='list-group mb0'>
                  <div className='list-group-item bt0'>
                    <Button onClick={() => this._download('change_position')} size='xs' color='info' className='btn-labeled float-right'>
                      <span className='btn-label'>
                        <i className='fa fa-download' />
                      </span>{' '}
                      Shkarko
                    </Button>
                    <em className='fa-fw fas fa-sync-alt mr-2' />
                    Ndryshimi i pozitës
                  </div>
                </div>
              </Col>
              <Col md={6} style={{ marginBottom: 10 }}>
                <div className='list-group mb0'>
                  <div className='list-group-item bt0'>
                    <Button onClick={() => this._download('minimum_standards')} size='xs' color='info' className='btn-labeled float-right'>
                      <span className='btn-label'>
                        <i className='fa fa-download' />
                      </span>{' '}
                      Shkarko
                    </Button>
                    <em className='fa fa-minus mr-2' />
                    Standartet Minimale
                  </div>
                </div>
              </Col>
              <Col md={6} style={{ marginBottom: 10 }}>
                <div className='list-group mb0'>
                  <div className='list-group-item bt0'>
                    <Button
                      onClick={() => this._download('job_duties_and_responsibilities')}
                      size='xs'
                      color='info'
                      className='btn-labeled float-right'
                    >
                      <span className='btn-label'>
                        <i className='fa fa-download' />
                      </span>{' '}
                      Shkarko
                    </Button>
                    <em className='fa fa-building mr-2' />
                    Detyrat dhe përgjegjësitë e punës
                  </div>
                </div>
              </Col>
              <Col md={6} style={{ marginBottom: 10 }}>
                <div className='list-group mb0'>
                  <div className='list-group-item bt0'>
                    <Button onClick={() => this._download('demotedInPosition')} size='xs' color='info' className='btn-labeled float-right'>
                      <span className='btn-label'>
                        <i className='fa fa-download' />
                      </span>{' '}
                      Shkarko
                    </Button>
                    {/* <i class='fa fa-level-down' aria-hidden='true'></i> */}
                    <em className='fa fa-arrow-circle-down mr-2' />
                    Ulje ne pozitë
                  </div>
                </div>
              </Col>
              <Col md={6} style={{ marginBottom: 10 }}>
                <div className='list-group mb0'>
                  <div className='list-group-item bt0'>
                    <Button onClick={() => this._download('suspension')} size='xs' color='info' className='btn-labeled float-right'>
                      <span className='btn-label'>
                        <i className='fa fa-download' />
                      </span>{' '}
                      Shkarko
                    </Button>
                    <em className='fa fa-hand-paper mr-2' />
                    Suspendim
                  </div>
                </div>
              </Col>
              {this.state.resingInterview &&
                this.state.resingInterview.length > 0 &&
                this.state.resingInterview.map((file, index) => (
                  <Col md={6} style={{ marginBottom: 10 }} key={index}>
                    <div className='list-group mb0'>
                      <div className='list-group-item bt0'>
                        <Button
                          onClick={() => this._downloadResignInterview(file._id)}
                          size='xs'
                          color='info'
                          className='btn-labeled float-right'
                        >
                          <span className='btn-label'>
                            <i className='fa fa-download' />
                          </span>{' '}
                          Shkarko
                        </Button>
                        <em className='fa-fw fas fa-user-times mr-2' />
                        Intervista Dalëse
                        <div className='m-0 text-muted'>
                          <small>{moment(file.createdAt).fromNow()}</small>
                        </div>
                      </div>
                    </div>
                  </Col>
                ))}
              <Col md={12} style={{ marginBottom: 10 }}>
                <div className='d-flex mt-4 mb-2'>
                  <strong className='mr-5'>Shkëputje kontratës</strong>
                </div>
                <div className=''>
                  <Collapse isOpen={true}>
                    <Card>
                      <Row style={{ marginTop: 10 }}>
                        <Col md={6}>
                          <div className='list-group mb0'>
                            <div className='list-group-item bt0'>
                              <Button
                                onClick={() => this._download('work_end', { type: 'trial_period_decision' })}
                                size='xs'
                                color='info'
                                className='btn-labeled float-right'
                              >
                                <span className='btn-label'>
                                  <i className='fa fa-download' />
                                </span>{' '}
                                Shkarko
                              </Button>
                              <em className='fa-fw fas fa-user-times mr-2' />
                              Vendim per nderprerjen e marredhenies se punes gjate periudhes provuese
                            </div>
                          </div>
                        </Col>
                        <Col md={6} style={{ marginBottom: 10 }}>
                          <div className='list-group mb0'>
                            <div className='list-group-item bt0'>
                              <Button
                                onClick={() => this._download('work_end', { type: 'notice_trial_period' })}
                                size='xs'
                                color='info'
                                className='btn-labeled float-right'
                              >
                                <span className='btn-label'>
                                  <i className='fa fa-download' />
                                </span>{' '}
                                Shkarko
                              </Button>
                              <em className='fa-fw fas fa-user-times mr-2' />
                              Njoftim per nderprerjen e marredhenies se punes gjate periudhes provuese
                            </div>
                          </div>
                        </Col>
                        <Col md={6} style={{ marginBottom: 10 }}>
                          <div className='list-group mb0'>
                            <div className='list-group-item bt0'>
                              <Button
                                onClick={() => this._download('work_end', { type: 'decision_to_terminate_by_agreement' })}
                                size='xs'
                                color='info'
                                className='btn-labeled float-right'
                              >
                                <span className='btn-label'>
                                  <i className='fa fa-download' />
                                </span>{' '}
                                Shkarko
                              </Button>
                              <em className='fa-fw fas fa-user-times mr-2' />
                              Vendim per nderprerje te marredhenies se punes me marreveshje
                            </div>
                          </div>
                        </Col>
                        <Col md={6} style={{ marginBottom: 10 }}>
                          <div className='list-group mb0'>
                            <div className='list-group-item bt0'>
                              <Button
                                onClick={() => this._download('work_end', { type: 'terminate_by_agreement' })}
                                size='xs'
                                color='info'
                                className='btn-labeled float-right'
                              >
                                <span className='btn-label'>
                                  <i className='fa fa-download' />
                                </span>{' '}
                                Shkarko
                              </Button>
                              <em className='fa-fw fas fa-user-times mr-2' />
                              Marreveshje per nderperje te marredhenies se punes
                            </div>
                          </div>
                        </Col>
                        <Col md={6} style={{ marginBottom: 10 }}>
                          <div className='list-group mb0'>
                            <div className='list-group-item bt0'>
                              <Button
                                onClick={() => this._download('work_end', { type: 'decision_to_terminate_3days' })}
                                size='xs'
                                color='info'
                                className='btn-labeled float-right'
                              >
                                <span className='btn-label'>
                                  <i className='fa fa-download' />
                                </span>{' '}
                                Shkarko
                              </Button>
                              <em className='fa-fw fas fa-user-times mr-2' />
                              Vendim per nderprerje te marredhenies se punes (Mungese nga zyra per me shume se tri 3 dite)
                            </div>
                          </div>
                        </Col>
                        <Col md={6} style={{ marginBottom: 10 }}>
                          <div className='list-group mb0'>
                            <div className='list-group-item bt0'>
                              <Button
                                onClick={() => this._download('work_end', { type: 'unilateral_resignation' })}
                                size='xs'
                                color='info'
                                className='btn-labeled float-right'
                              >
                                <span className='btn-label'>
                                  <i className='fa fa-download' />
                                </span>{' '}
                                Shkarko
                              </Button>
                              <em className='fa-fw fas fa-user-times mr-2' />
                              Vendim për ndërprerjen e marrëdhënies së punës-sipas dorëheqjes së njëanëshme
                            </div>
                          </div>
                        </Col>
                      </Row>
                    </Card>
                  </Collapse>
                </div>
              </Col>
              <Col md={12} style={{ marginBottom: 10 }}>
                <div className='list-group mb0'>
                  <div className='list-group-item bt0'>
                    <Button onClick={() => this._sendEmail()} size='md' color='info' className='btn-labeled float-right'>
                      <span className='btn-label'>
                        <i className='fa fa-envelope' />
                      </span>{' '}
                      Dërgo email
                    </Button>
                    <em className='fa-fw fas fa-check mr-2 mt-2' />
                    Njofto punëtorin për kontraten e re
                  </div>
                </div>
              </Col>
            </Row>
          </CardBody>
        </Card>
      </Fragment>
    )
  }
}

export default UserForms
